import { createSelector } from '@ngrx/store';

export const selectBooks = state => state.books;

export const BookList = createSelector(selectBooks, state => state.bookList);

export const BookListIndex = createSelector(selectBooks, state => state.startIndex);

export const Loading = createSelector(selectBooks, state => state.loading);

export const Total = createSelector(selectBooks, state => state.totalItems);

export const Errors = createSelector(selectBooks, state => state.error);
