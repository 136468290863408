<app-search></app-search>

<ng-template [ngIf]="(books | async).length <= 0">
  <section class="start-search">
    <p>Search for any book!</p>
  </section>
</ng-template>

<ng-template [ngIf]="(books | async).length > 0">
  <section *ngIf="!(booksError | async); else errorView">
    <div class="book-list" *ngFor="let book of books | async" [@enterBook]>
      <div>
        <img
          *ngIf="book.volumeInfo.imageLinks; else defaultImg"
          src="{{ book.volumeInfo.imageLinks.thumbnail }}"
        />
      </div>
      <div>
        <div class="title">
          <div>
            <h2 *ngIf="book.volumeInfo.subtitle">{{ book.volumeInfo.subtitle }}</h2>
            <h1>{{ book.volumeInfo.title }}</h1>
          </div>
          <span *ngIf="book.volumeInfo.publishedDate"
            >Published On: {{ book.volumeInfo.publishedDate | dateFormat }}</span
          >
        </div>

        <p *ngIf="book.volumeInfo.description; else empty">{{ book.volumeInfo.description }}</p>
        <div class="info-links">
          <a
            *ngIf="book.volumeInfo.previewLink"
            href="{{ book.volumeInfo.previewLink }}"
            target="_blank"
            >Preview</a
          >
          <span *ngIf="book.volumeInfo.previewLink && book.saleInfo.buyLink">|</span>
          <a *ngIf="book.saleInfo.buyLink" href="{{ book.saleInfo.buyLink }}" target="_blank"
            >Buy</a
          >
        </div>
      </div>
    </div>
    <div *ngIf="getLoading | async" class="loading">
      Loading <span class="bounce1"></span><span class="bounce2"></span><span></span>
    </div>
  </section>
</ng-template>

<ng-template #empty>
  <p>
    Sorry no description at this time!
  </p>
</ng-template>

<ng-template #errorView>
  <section class="error">
    <p>
      Looks like something when wrong! Try again later!!
    </p>
  </section>
</ng-template>

<ng-template #defaultImg><img src="assets/image-not-found-1038x576.png"/></ng-template>
