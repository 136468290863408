import { Action } from '@ngrx/store';
import { BooksSearch } from './books-reducer';

export const BOOKS_RESULTS = '[books] BOOKS_RESULTS';
export const ADD_BOOKS = '[books] ADD_BOOKS';
export const BOOK_SEARCH = '[books] BOOK_SEARCH';
export const SET_LOADING = '[books] loading';
export const SET_INDEX = '[books] set index';
export const SET_TOTAL_ITEMS = '[books] SET_TOTAL_ITEMS';
export const GET_MORE_BOOKS = '[books] GET_MORE_BOOKS';
export const RESULTS_ERROR = '[books] RESULTS_ERROR';

export class BookResults implements Action {
  readonly type = BOOKS_RESULTS;
  constructor(public payload: any) {}
}

export class AddBooks implements Action {
  readonly type = ADD_BOOKS;
  constructor(public payload: any) {}
}

export class BookSearch implements Action {
  readonly type = BOOK_SEARCH;
  constructor(public payload: BooksSearch) {}
}

export class GetMoreBooks implements Action {
  readonly type = GET_MORE_BOOKS;
  constructor(public payload: number) {}
}

export class SetLoading implements Action {
  readonly type = SET_LOADING;
  constructor(public payload: boolean) {}
}

export class SetIndex implements Action {
  readonly type = SET_INDEX;
  constructor(public payload: number) {}
}

export class SetTotalItems implements Action {
  readonly type = SET_TOTAL_ITEMS;
  constructor(public payload: number) {}
}

export class ResultsError implements Action {
  readonly type = RESULTS_ERROR;
  constructor(public payload: boolean) {}
}

export type BookActions =
  | BookResults
  | AddBooks
  | BookSearch
  | SetLoading
  | SetIndex
  | SetTotalItems
  | GetMoreBooks
  | ResultsError;
