<section>
  <form action="#" novalidate #f="ngForm" (ngSubmit)="onSubmit(f)">
    <input type="search" placeholder="Search" name="search" required [(ngModel)]="search" />
    <label>
      <span>Order By:</span>
      <select name="orderBy" value="relevance" [(ngModel)]="relevanceOrder">
        <option value="relevance">Relevance</option>
        <option value="newest">Newest</option>
      </select>
    </label>
    <button type="submit">Search</button>
  </form>
</section>
