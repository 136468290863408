import { BooksStore, booksReducer } from './../components/books-list/store/books-reducer';

import { ActionReducerMap } from '@ngrx/store';

export interface AppState {
  books: BooksStore;
}

export const reducers: ActionReducerMap<AppState> = {
  books: booksReducer
};
