import * as BookActionTypes from './books-actions';

interface Actions {
  type: string;
  payload?: any;
}

export interface BooksSearch {
  title: string;
  startIndex: number;
  orderBy: string;
}

export interface BooksStore {
  bookList: any;
  loading: boolean;
  startIndex: number;
  totalItems: number;
  error: boolean;
}

const initialState: BooksStore = {
  bookList: [],
  loading: false,
  startIndex: 0,
  totalItems: null,
  error: false
};

export function booksReducer(state = initialState, action: BookActionTypes.BookActions) {
  switch (action.type) {
    case BookActionTypes.BOOKS_RESULTS:
      return {
        ...state,
        bookList: action.payload,
        loading: false
      };
    case BookActionTypes.ADD_BOOKS:
      return {
        ...state,
        bookList: [...state.bookList, ...action.payload],
        loading: false
      };
    case BookActionTypes.SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case BookActionTypes.SET_INDEX:
      return {
        ...state,
        startIndex: action.payload
      };
    case BookActionTypes.SET_TOTAL_ITEMS:
      return {
        ...state,
        totalItems: action.payload
      };
    case BookActionTypes.RESULTS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    default:
      return { ...state };
  }
}
