import { BookList, BookListIndex, Errors, Loading, Total } from './store/books-selectors';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { debounceTime, distinctUntilChanged, withLatestFrom } from 'rxjs/operators';

import { AppState } from './../../store/app.reducers';
import { GetMoreBooks } from './store/books-actions';
import { Subject } from 'rxjs';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'app-books-list',
  templateUrl: './books-list.component.html',
  styleUrls: ['./books-list.component.sass'],
  animations: [
    trigger('enterBook', [
      state('in', style({ opacity: 1 })),
      state('hide', style({ opacity: 0 })),
      state('show', style({ opacity: 1 })),
      transition('show <=> hide', animate('0.5s ease-in-out')),
      transition('void => *', [
        style({
          opacity: 0
        }),
        animate('0.4s ease-in')
      ])
    ])
  ]
})
export class BooksListComponent implements OnInit, OnDestroy {
  constructor(private store: Store<AppState>) {}

  private scrollSubject = new Subject();
  private scrollObservable = this.scrollSubject.asObservable();

  books = this.store.pipe(select(BookList), distinctUntilChanged(), untilComponentDestroyed(this));

  booksError = this.store.pipe(
    select(Errors),
    distinctUntilChanged(),
    untilComponentDestroyed(this)
  );

  getIndex = this.store.pipe(
    select(BookListIndex),
    distinctUntilChanged(),
    untilComponentDestroyed(this)
  );

  getLoading = this.store.pipe(
    select(Loading),
    distinctUntilChanged(),
    untilComponentDestroyed(this)
  );

  getTotal = this.store.pipe(select(Total), distinctUntilChanged(), untilComponentDestroyed(this));

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    this.scrollSubject.next(window.innerHeight + window.scrollY);
  }

  ngOnInit(): void {
    this.scrollObservable
      .pipe(
        debounceTime(200),
        withLatestFrom(this.getIndex),
        withLatestFrom(this.getLoading),
        withLatestFrom(this.getTotal)
      )
      .subscribe((data: [[[number, number], boolean], number]) => {
        const [[[scrollValue, index], loading], total] = data;

        if (!loading && index < total && scrollValue >= document.body.offsetHeight - 1500) {
          this.store.dispatch(new GetMoreBooks(index));
        }
      });
  }

  ngOnDestroy(): void {
    this.scrollSubject.unsubscribe();
  }
}
