import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {
  transform(value: string): string {
    const [year, month] = value.split('-');

    if (!month) {
      return value;
    }
    return `${month}/${year}`;
  }
}
