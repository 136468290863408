import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  AddBooks,
  BOOK_SEARCH,
  BookResults,
  GET_MORE_BOOKS,
  ResultsError,
  SetIndex,
  SetLoading,
  SetTotalItems
} from './books-actions';
import { catchError, map, switchMap } from 'rxjs/operators';

import { AppState } from './../../../store/app.reducers';
import { BooksSearch } from './books-reducer';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';

@Injectable()
export class BooksEffects {
  private url = 'https://www.googleapis.com/books/v1/volumes?';
  private API_KEY = 'AIzaSyDUbvTsz_KKbkOtJVIttn9xXLSNM0p8AVw';

  private title;
  private orderBy;

  @Effect()
  getBooks = this.actions$.pipe(
    ofType(BOOK_SEARCH),
    switchMap((searchData: { payload: BooksSearch }) => {
      const { title, orderBy, startIndex } = searchData.payload;

      this.title = title;
      this.orderBy = orderBy;
      this.store.dispatch(new SetLoading(true));

      return this.http
        .get(
          `${this.url}q=${title}&startIndex=${startIndex}&orderBy=${orderBy}&maxResults=20&key=${this.API_KEY}`
        )
        .pipe(
          map((books: any) => {
            this.store.dispatch(new SetIndex(startIndex + 1));
            this.store.dispatch(new SetTotalItems(books.totalItems));
            return new BookResults(books.items);
          }),
          catchError(() => {
            return of(new ResultsError(true));
          })
        );
    })
  );

  @Effect()
  addMoreBooks = this.actions$.pipe(
    ofType(GET_MORE_BOOKS),
    switchMap((data: { payload: number }) => {
      const { payload } = data;

      this.store.dispatch(new SetLoading(true));

      return this.http
        .get(
          `${this.url}q=${this.title}&startIndex=${payload}&orderBy=${this.orderBy}&maxResults=20&key=${this.API_KEY}`
        )
        .pipe(
          map((books: any) => {
            this.store.dispatch(new SetIndex(payload + 1));
            this.store.dispatch(new SetTotalItems(books.totalItems));
            return new AddBooks(books.items);
          }),
          catchError(() => {
            return of(new ResultsError(true));
          })
        );
    })
  );

  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private store: Store<AppState>
  ) {}
}
