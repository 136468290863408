import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { AppState } from './../../store/app.reducers';
import { BookListIndex } from '../books-list/store/books-selectors';
import { BookSearch } from './../books-list/store/books-actions';
import { distinctUntilChanged } from 'rxjs/operators';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.sass']
})
export class SearchComponent implements OnInit, OnDestroy {
  constructor(private store: Store<AppState>) {}

  currentIndex;
  relevanceOrder = 'relevance';
  search = '';

  getIndex = this.store.pipe(
    select(BookListIndex),
    distinctUntilChanged(),
    untilComponentDestroyed(this)
  );

  ngOnInit(): void {
    this.getIndex.subscribe(v => {
      this.currentIndex = v;
    });
  }

  onSubmit(f) {
    if (f.valid) {
      this.store.dispatch(
        new BookSearch({
          title: f.value.search,
          startIndex: 0,
          orderBy: f.value.orderBy
        })
      );

      this.search = '';
      this.relevanceOrder = 'relevance';
    }
  }

  ngOnDestroy(): void {}
}
